import { takeEvery, call, put } from 'redux-saga/effects';
import * as API from 'api';
import localStore from 'helpers/localstore';

import * as actions from './auth.action';

function* auth(inputData: any) {
  const { username, password } = inputData.payload;
  try {
    yield put(actions.setMessage(''));
    const authRes = yield call(API.AUTH_LOGIN, { email: username, password });
    if (authRes.text) {
      const { success, data } = JSON.parse(authRes.text);
      if (success) {
        const token = data.jwt;
        yield put(actions.setMessage(''));
        console.log('token ====>', data.jwt);
        localStore.setToken(data.jwt);
        const profileRes = yield call(API.getProfile);
        yield put(actions.setProfile({ ...JSON.parse(profileRes.text), token }));
      } else {
        yield put(actions.setMessage(data.message));
      }
    }
  } catch (e) {
    console.log(e);
    yield put(actions.setMessage('Connection Error'));
  }
  //   yield put(actions.setCollections(colecRes.body.collections));
}

export default function* watchAuthSaga() {
  yield takeEvery(actions.auth, auth);
}
